const localStorageIsAvailable = () => {
  const test = 'test'
  try {
      window.localStorage.setItem(test, test)
      window.localStorage.removeItem(test)
      return true
  } catch (e) {
      return false
  }
}
// Polyfill for localstorage
export const polyfill = () => {
  if (!localStorageIsAvailable()) {
    window.localStorage = {
      _data       : {},
      setItem     : (id, val) => { return this._data[id] = String(val) },
      getItem     : (id) => { return this._data.hasOwnProperty(id) ? this._data[id] : undefined },
      removeItem  : (id) => { return delete this._data[id] },
      clear       : () => { return this._data = {} }
    }
  }
}
export const setItem = (key, item) => {
  if (typeof window === 'undefined') return () => {}
  window.localStorage.setItem(key, JSON.stringify(item))
  return window.localStorage.getItem(key)
}
export const getItem = (key) => {
  if (typeof window === 'undefined') return () => {}
  return JSON.parse(window.localStorage.getItem(key))
}
